/* You can add global styles to this file, and also import other style files */
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

html, body { height: 100%; font-family: 'proxima_novaregular'; }
body { margin: 0;}
@font-face {
    font-family: 'proxima_novabold';
    src: url('assets/fonts/proxima_nova_bold-webfont.woff2') format('woff2'),
         url('assets/fonts/proxima_nova_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novasemibold';
    src: url('assets/fonts/proxima_nova_semibold-webfont.woff2') format('woff2'),
         url('assets/fonts/proxima_nova_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaregular';
    src: url('assets/fonts/proximanova-regular-webfont.woff2') format('woff2'),
         url('assets/fonts/proximanova-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
/***css gobal***/
$bluebg: #072D94;
$redbg:Red;
$drakgraycolor:#333333;
$lightgraycolor:#666666;
$textwhite:#fff;
$black:#000;
$font18: 18px;
$font20: 20px;
$font14: 14px;
$font16: 16px;
$font15: 15px;
$font12: 12px;
$rounded:10px;
$rounded:15px;
$loginbg:#F2F2F2;
$btnblue:#437CDC;
$graytext:#666666;

.cursorpointer{cursor: pointer;}	
.font18{ font-size:$font18 !important;}
.font20{ font-size:$font20 !important;}
.font14{font-size: $font14 !important;}
.font15{font-size: $font15 !important;}
.font12{font-size: $font12;}
.pt{padding-top: 54px;}
.textblack{color: $black;}
.fw500{font-weight: 500;}
.fw400{font-weight:400;}
.fw600{font-weight:600 !important;}
.fw700{font-weight:700;}
.graycol{color: $graytext;}
.graytext{color:#666666;}
.textblue{color: $bluebg !important;}
.textred{color: $redbg !important;}
.round15{border-radius: 15px;}
/***nav bar css***/
.navbar{background-color:$bluebg;}
.nav-link{ color:$textwhite !important; font-size: $font18 !important;}
input{height:56px; border: 1px solid #CACACA; border-radius:12px;}
select{height:56px; border: 1px solid #CACACA; border-radius:12px; background-color:#ccc;}
.form-select{border: 1px solid #CACACA !important; border-radius:12px !important; height:56px !important; background-color:#f2f2f2 !important;}
.form-select::placeholder{color:#BEBEBE !important;}
input::placeholder{color:#BEBEBE !important;}
/***buttons css***/
.btn.btn_black{
    background-color: $black; 
    color: $textwhite;
     border-radius:$rounded;
      width:9rem;
    height: 3.125rem; 
    font-size: $font20;
}
.btn.btn_black:hover{
    background-color: $black !important;
    color: $textwhite;
    }
.signup_btn{background-color:#437CDC !important; width:100%; height: 64px; color:$textwhite !important; font-size: 22px;
        &:hover{background-color:#437CDC !important; color:$textwhite !important;}
}

.view_btn{background-color:#EDF2FC !important;  color:$black !important; font-size: 18px;
    &:hover{background-color:#EDF2FC !important; color:$black !important;}
}

/***login css***/
.login_wrap{
    background-color:$loginbg;
    border-radius: 40px;

    h3{ font-size: 32px; font-weight: 700; color:$drakgraycolor;}

    label{ font-size: $font16; color:$lightgraycolor; font-weight: 600;}

    input{height: 56px; border-radius: 12px; border: 1px solid #C2C2C2; background-color: transparent;}

    .form-check-input{height: 20px; width: 20px; margin-right:8px;}

    button{background-color:#437CDC; width:100%; height: 64px; color:$textwhite; font-size: 22px;
        &:hover{background-color:#437CDC; color:$textwhite;} &:active{background-color:#437CDC; color:$textwhite;}
    }
   .eye_on {
        position: absolute;
        top: 62px;
        right: 24px;
    }

    a{color: #111111; text-decoration: none;}
    .cont_text{
        text-align: center; position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 32%;
        height: 1px;
        background-color: #c9c6c6;
        top: 14px;
        left: 17px;
    }
    &:after {
        content: "";
        position: absolute;
        width: 32%;
        height: 1px;
        background-color: #c9c6c6;
        top: 14px;
        right: 14px;
    }
}

}

.social-networks {
    margin:0;
    padding: 0;
    text-align: center;
  
    li {
        display: inline-block;
        background-color: #fff;
        border-radius: 100px;
        padding: 7px;
        width: 38px;
        height: 38px;
        margin: 0 6px;
        line-height: 18px;
    }
  
    a {
      display: block;
  
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
  .signup{ color:#666666;}

  ngb-rating span {
    color: #FFC328;
    font-size:28px;
  }

// /* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';

/***home css***/
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #0A142F !important;
}
.owl-theme .owl-dots .owl-dot span {
    width: 15px !important;
    height: 15px !important;
    margin: 5px 7px;
    background: #7f8081 !important;
    display: block;
    backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}
/***date css***/
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color:transparent;
    width: 100%;
}
.mat-mdc-form-field{   width: 100%;}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before{display: none;}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: #D9E5F8 !important;
    padding: 9px 5px;
}
.mdc-evolution-chip-set .mdc-evolution-chip {
    margin-top: 4px;
    margin-bottom: 10px !important;
}
.mat-mdc-form-field-flex {
    border: 1px solid #dee2e6;
    border-radius: 7px;
    padding-left: 13px;
}
.mdc-text-field{padding: 0 1px !important;}
mat-slider.mat-mdc-slider{height:auto !important;}

mat-slider.mat-mdc-slider input {
    border: 0px !important;
    height: auto !important;
    border-radius: 0px !important;
}
.mat-mdc-slider{width: 100% !important;}
.mdc-slider .mdc-slider__thumb{top: -12px !important;}
.error_message {
    color: red;
}
.mat-mdc-form-field-subscript-wrapper{display: none;}

.mat-datepicker-toggle {
    position: absolute;
  right: 0px;
   top: 7px;
}
.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

/***tab css***/
.mat-mdc-tab-body-content {
    height: 100%;
    overflow: inherit !important;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #437cdc;
    // font-size: 18px;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
    // color: rgb(255, 255, 255);
    font-size: 18px;
}
.mat-mdc-tab-header {
    width: 44%;
}
// .mat-mdc-tab-body-wrapper{border-top: 1px solid #ccc;}
.btn_whitebg {
    background-color: #fff;
    width: 32px;
    height: 32px;
    padding: 4px;
}

/***create profile css***/
.mat-mdc-tab-header-pagination{display: none !important;}
.login_wrap.create_block .mat-mdc-tab {
    background-color: transparent!important;
}
.create_block .mat-mdc-tab-header {
    width: 100%;
    background-color: #437CDC;
    border-radius: 100px;
    padding: 10px;
    margin-bottom: 16px;
}
.create_block .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: var(--mdc-tab-indicator-active-indicator-color, #3f51b5);
    display: none;
}
.create_block .mat-mdc-tab-body-wrapper {
    border-top: 0px;
}
.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before, .mat-mdc-tab.mdc-tab--active .mat-ripple-element {
    background-color: #000 !important;
}
.login_wrap.create_block .mdc-tab-indicator--active {
    background-color: #fff !important;
    border-radius: 100px;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
    font-size:16px;
}
// .my-jobs-block{height: 100% !important;}

.jobs_detail .mat-mdc-tab-header{overflow: inherit !important;}
.jobs_detail .mat-mdc-tab-label-container {
    position: absolute;
    margin-top: -63px;
    right: 0;
}
.jobs_detail .mat-mdc-tab-body-wrapper {
    border-top:0px;
}
.jobs_detail .mat-mdc-tab-header {
    width:100%;
}
.jobs_tab .mat-mdc-tab-header {
    width: auto;
}
.jobs_tab .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
    color: #8C8F95;
    font-size: 18px;
}
.jobs_tab .mat-mdc-tab-body-wrapper {
    border-top:0;
}
.jobs_tab .mat-mdc-tab-header {
    width: 50%;
    margin-bottom: 26px;
}
.proposal_block .mat-mdc-tab-header {
    width: 30%;
    margin-bottom: 4px;
    margin-top: 21px;
}
/***post a job***/
.radio_block .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color:#437CDC;
    border-color:#437CDC;
}
.radio_block .btn-outline-success, .radio_block .btn-outline-success:hover  {
    background-color: #E5EDFA;
    color: #437CDC;
    border: 1px solid #e5edfa;
}

.radio_block .btn-check + .btn:hover {
	color: #fff;
	background-color: #437cdc;
	border-color: #437cdc;
}
.job_detail .mat-mdc-tab-header {
    width: 30%;
    margin-bottom: 10px;
}
.job_detail .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
    color: #9e9a9a;
    font-size: 18px;
}
.mat-mdc-select-trigger{
    width: 98% !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
    width: 47%;
    margin: auto;
}

.nsm7Bb-HzV7m-LgbsSe {
    color: #6c7277;
    font-size: 17px;
    height: 61px;
    padding: 0 50px;
    width: 99% !important;
    min-width: 96% !important;
    max-width: 98% !important;
}
.jobs_detail.client-detail .mat-mdc-tab-label-container{
    position: absolute;
    margin-top: -57px;
    right: 175px;
}

.jobs_detail.client-detail .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-top-width: inherit;
}
.job_detail.client-detail .mat-mdc-tab{ font-size: 16px; padding-right: 10px;}
.jobs_detail.client-detail .mdc-tab__content {
    align-items: center;
}
.mat-mdc-option{font-size: 14px;}
.mat-mdc-option .mat-pseudo-checkbox-minimal {
    margin-left: 7px;
    flex-shrink: 0;
}

/***css rotate***/
.order_warp .login_order {
    order: 2;
}
.btn-outline-success .signup_order {
    order: 1;
}
.order_warp .navbar-nav li:nth-child(1) {
    order: 5;
}
.order_warp .navbar-nav li:nth-child(2) {
    order: 4;
}
.order_warp .navbar-nav li:nth-child(3) {
    order: 3;
}
.order_warp .navbar-nav li:nth-child(4) {
    order: 2;
}
.order_warp .navbar-nav li:nth-child(5) {
    order: 1;
}
.order_warp .navbar-brand {
    order: 1;
}
.order_warp .navbar-nav-scroll {
    order: 1;
}
.order_warp .left_text_order {
    order: 1;
    text-align: right;
}
.order_warp .right_img_order {
    text-align: left !important;
}
.order_warp .order{order: 1;}

.order_warp .contact_us {
    text-align: right;
}
.order_warp .contact_us input {
    text-align: right;
}
.order_warp .contact_us textarea {
    text-align: right;
}
.order_warp .newsletter p {
    order: 1;
}
.order_warp .order4 {
    order: 4;
}
.order_warp .order3 {
    order: 3;
}
.order_warp .order2 {
    order: 2;
}
.order_warp .order1 {
    order: 1;
}

.order_warp .navbar .dropdown-item {
    text-align: right;
}


// .order_warp label.form-label {
//     display: flex;
// }
.order .form-label{display:flex;}
.order_warp .login_wrap input{text-align:right;}
.order_warp .login_wrap .eye_on {
    left: 29px;
    right: inherit;
}
.order_warp .login_wrap .form-check-input {
    margin-right: 3px;
    float: right;
    margin-left: 8px;
}
.order_warp .login_order {
    order: 3;
}
.order_warp .btn.btn_black{order:2;}
.order_warp .select_block {
    margin-right: 8px;
}
.order_warp footer{text-align: right;}

.order_warp .social-link{justify-content: end;}

.order_warp .justifytext {
    justify-content: end !important;
}
.order_warp .textend{text-align: end; width: 100%;}
.order_warp .side_block ul li{
    text-align: right;
    padding-right: 10px;
}
.order_warp .personal_block{text-align: right;}
.order_warp .side_block {
    text-align: right;
}
.order_warp ul.stepper li:nth-child(1){
    order: 7;
}
.order_warp ul.stepper li:nth-child(2){
   order: 6;
}
.order_warp ul.stepper li:nth-child(3){
    order: 5;
}
.order_warp ul.stepper li:nth-child(4){
    order: 4;
}
.order_warp ul.stepper li:nth-child(5){
    order: 3;
}
.order_warp ul.stepper li:nth-child(6){
    order: 2;
}
.order_warp ul.stepper li:nth-child(7){
    order: 1;
}
.order_warp .textalign{text-align: right !important;}
.order_warp .mat-mdc-tab-header{margin-left: auto;}
.order_warp .my_profile p{justify-content: end;}
.order_warp .search_box .form svg{
    position: absolute;
    top: 21px;
    right: 0;
    left: 0;
}
.order_warp .cross_btn{
    position: absolute;
    top: 1px;
    right: 0;
    left: 22px;
}
.order_warp .side_menu {
    right: 0;
    left: inherit !important;
}
.order_warp .right_content{
    margin-left:0 !important;
}
.order_warp .clear {
    clear: both;
}
.order_warp .my-jobs-block  ul.project_detail::before {
    right: inherit !important;
    background-color: transparent !important;
}
.order_warp .my-jobs-block .user_profile_block{
    margin-left:inherit !important;
}
.order_warp .jobs_detail .mat-mdc-tab-label-container {
    left:0;
    right: inherit !important;
}
.order_warp .margin {
    margin-left: inherit !important;
}
.order_warp ul.user-photo-block li:last-child {
    margin-left:inherit !important;
}
.order_warp .my-jobs-block .price-block  ul{
    padding-left: 12px;
}
.order_warp  .my-jobs-block .rating_box{
    width: 21% !important;
}
.order_warp .my-jobs-block .price-block li:nth-child(1) {
    border-left: 1px solid #ccc;
    padding-right: 12px !important;
    padding-left: 11px;
}
.order_warp .my-jobs-block .price-block ul{
    gap: 26px !important;
    padding: 0px 13px;
}
.order_warp .my-jobs-block ul.project_detail li{
    padding-right: 1px;
    vertical-align: middle;
}
// .order_warp .logo-english {
//     display: none;
// }
.mdc-menu-surface.mat-mdc-select-panel {
    margin: 43px 0 !important;
}
// .order_warp .logo-arable {
//     display: block !important;
// }

.order_warp .stepper-vertical li:not(:last-child):after {
    position: absolute;
    content: "";
    background-color: #BEBEBE;
    top:2.2rem;
    left:-5.9rem !important;
    width:171.5px;
    height: 2px;
}
.order_warp .form-control{
    text-align: right;
    margin-left:6px;
}
.order_warp .right-form .eye_on {
    position: absolute;
    top: 48px;
    left: 12px;
}
.order_warp .right-content{
    display: flex;
justify-content: end !important;
}
.order_warp .portfolio_block {
    width: 22%;
    margin-left: auto;
}
.order_warp .direction{
    direction: rtl;
}
.order_warp .logo-arable {
    padding-left: 38px;
    margin-right: 0;
}
.order_warp .clickmouse{cursor: pointer;}
.order_warp .form-select{background-position: 2% !important; text-align: right;}
.order_warp .mat-datepicker-toggle {
    position: absolute;
    left: 0px;
    top: 7px;
    right: inherit !important;
}
.order_warp .right-form .eye_on {
    position: absolute;
    top: 48px;
    right: inherit !important;
}
.order_warp .form-check.checkblock {
    width: 35%;
}
.order_warp .client-detail .mat-mdc-tab-label-container {
    left: 130px;
    right: inherit !important;
}
.order_warp .search_box .form svg{
    position: absolute;
    top: 21px;
    left: 45px;
    right: initial !important;
}
.order_warp .right-content.add-right {
    justify-content: inherit !important;
}
.order_warp .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    left: inherit;
    right: 0;
}
.order_warp .meauto{margin-right: auto;}
.order_warp .mlauto{margin-left: auto !important;}
.order_warp .lefttext{text-align: left !important;}
.order_warp .btn-close{margin-left: 0px !important;}
.order_warp .remove_block h5{font-size: 14px; padding-top: 6px;}
.order_warp .my-jobs-block .comment_block {
    margin-left: 0px !important;
    margin-right: auto !important;
}
.order_warp .error_message {
    color: red;
    text-align: right;
}
.order_warp .social-link-block {
    /* justify-content: end; */
    justify-content: flex-start;
}
.order_warp .form-select{border: 0px !important;}
.order_warp .mat-datepicker-input {
    text-align: right;
}
.newsletter .send_btn{
    right: inherit;
    left: 20px;
}
// .order_warp .mat-datepicker-toggle{left: 0px;}
// .order_warp .mat-mdc-form-field-infix {
//     width: 100px;
//     order: 2;
//     margin-left: auto !important;
//     flex: inherit !important;
//     text-align: right !important;
// }
// .navbar-brand.logo-arable {
//     display: none;
// }
/***css language***/
// .mat-mdc-select-value {
//     color: #fff;
// }
// .mat-mdc-select-arrow {
//     color: #fff;
// }
// .cdk-overlay-pane{
//     top: 70px !important;
// }

.mat-select-search-inner-row {
    position: relative;
    z-index: 999;
    background-color: #fff;
}
.mat-mdc-select-value {
    color: #fff;
}
.my-jobs-block .mat-mdc-select-value{
    color: #000000 !important;
} 
.pargh_block{
    max-width:100%;
    /* word-break: break-word; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 18px;
    white-space: pre-wrap;
    height: 90px;
}
.select_block .mat-mdc-select-value {
    color: #000;
}
.mat-mdc-select-arrow {
    margin-top: -22px;
}
.select_block .mat-mdc-select-arrow{
    margin-top: 0;
}
.side_block .mat-mdc-select-value {
    color: #000;
}
.side_block .mat-mdc-select-arrow {
    margin-top: 0;
}
#exampleFormControlSelect1-panel {
    margin: 10px -13px !important;
}


@media (max-width:1600px) {
    .personal_block{
        width: 74% !important;
    }
  }

.form-check-input {
    border: 1px solid #a9a0a0 !important;
}
.pdf_box p {
    word-wrap: break-word;
    max-width: 238px;
    /* word-break: break-word; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 18px;
    white-space: pre-wrap;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
}
.freelancer_block .form-check-input{width: 1.5rem; height: 1.5rem;}
.mat-expansion-panel-body {
    padding: 0 0px 1px !important;
}
.accordion-body.ng-tns-c51-6 {
    padding-top: 0 !important;
}
.mat-expansion-panel{
    box-shadow: inherit !important;
    border-bottom: 1px solid #e7e5e5 ;
    border-radius: 0px !important;
}

mat-datepicker-toggle {
    z-index: 99999; /* Make sure it's lower than the modal's z-index */
  }

  ::ng-deep .mat-datepicker-toggle {
    z-index: 99999;
  }

  .cdk-overlay-container {
    z-index: 99999; /* Adjust the value accordingly */
  }

  .wordBreak{
    word-wrap: break-word;
  }


  @media screen and (max-width: 600px) {
    
    .navbar-toggler{background-color: #fff !important;}
    .dropdown-menu.menu_block {
        width: 100%;
        padding:2px;
        margin-top: 0;
        left: -301px;
    }
    .dropdown-toggle::after{top:11px !important;}
    .login_wrap .cont_text:before {
        width: 24%;
        top: 12px;
        left: 17px;
    }
    .login_wrap .cont_text:after {
        width: 26%;
        height: 1px;
        top: 14px;
        right: 14px;
    }
    .mat-mdc-tab-header {
        width: auto;
    }
}
.page_content_block ul li {
    line-height: 34px;
}

.page_content_block h3 {
    font-size: 22px;
    color: #17327a;
    font-weight: bold;
}
.page_content_block h4 {
    font-size: 22px;
    color: #17327a;
    font-weight: bold;
}